<template>
  <div class="auth-wrapper auth-v2">
    <b-row v-if="!hideFirstPage && currentUser" class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!--        <vuexy-logo />-->
        <h1>
          <strong class="font-weight-700 font-large-3" style="font-weight:900">hyre<span
              class="text-success">.</span></strong>
        </h1>

        <h2 class="brand-text text-primary ml-1"/>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
          class="d-none d-lg-flex align-items-center "
          lg="7" style="background-color: #F0F8FC;"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <svg fill="none" height="900" viewBox="0 0 812 900" width="812" xmlns="http://www.w3.org/2000/svg">
            <rect fill="#E5E5E5" height="900" width="812"/>
            <rect fill="white" height="900" width="1440"/>
            <rect fill="#F0F8FC" height="900" width="812"/>
            <circle cx="676" cy="184" fill="#DCEEF8" r="19"/>
            <circle cx="105" cy="144" fill="#DCEEF8" r="13"/>
            <ellipse cx="584" cy="830.5" fill="#DCEEF8" rx="11" ry="11.5"/>
            <path
                d="M413.651 836.526C591.956 836.526 736.501 691.981 736.501 513.676C736.501 335.371 591.956 190.826 413.651 190.826C235.346 190.826 90.8008 335.371 90.8008 513.676C90.8008 691.981 235.346 836.526 413.651 836.526Z"
                fill="#DCEEF8"/>
            <path
                d="M598.684 195.652C450.178 86.3244 93.05 113.131 54.0542 302.597C15.0584 492.064 212.836 506.414 266.269 659.135C319.702 811.856 505.406 877.313 673.317 747.048C841.228 616.784 747.189 304.979 598.684 195.652Z"
                fill="#DCEEF8"/>
            <path clip-rule="evenodd"
                  d="M299.881 151.748H577.081C582.549 151.741 586.981 156.174 586.981 161.641C586.981 161.643 586.981 161.645 586.975 161.648L586.7 596.1C586.69 601.565 582.259 605.994 576.794 606H299.881C294.413 606 289.981 601.568 289.981 596.1V161.648C289.981 156.18 294.413 151.748 299.881 151.748Z"
                  fill="#666E78"
                  fill-rule="evenodd"/>
            <path clip-rule="evenodd"
                  d="M314.142 174.259H563.622C568.543 174.254 572.532 178.243 572.532 183.164C572.532 183.165 572.532 183.167 572.527 183.169L572.279 573.774C572.271 578.693 568.282 582.679 563.364 582.684H314.142C309.222 582.684 305.232 578.695 305.232 573.774V183.169C305.232 178.248 309.222 174.259 314.142 174.259Z"
                  fill="#F4F7FF"
                  fill-rule="evenodd"/>
            <path
                d="M438.882 164.612C450.63 164.612 460.154 155.073 460.154 143.306C460.154 131.539 450.63 122 438.882 122C427.134 122 417.61 131.539 417.61 143.306C417.61 155.073 427.134 164.612 438.882 164.612Z"
                fill="#171822"/>
            <path
                d="M438.882 156.572C446.197 156.572 452.127 150.633 452.127 143.306C452.127 135.979 446.197 130.04 438.882 130.04C431.567 130.04 425.637 135.979 425.637 143.306C425.637 150.633 431.567 156.572 438.882 156.572Z"
                fill="#F4F7FF"/>
            <path
                d="M496.684 142.904H381.081C379.976 142.904 379.081 143.799 379.081 144.904V180.299C379.081 181.404 379.976 182.299 381.081 182.299H496.684C497.789 182.299 498.684 181.404 498.684 180.299V144.904C498.684 143.799 497.789 142.904 496.684 142.904Z"
                fill="#171822"/>
            <path d="M538.016 290.033H505.105V322.996H538.016V290.033Z" stroke="#B2B2B2" stroke-width="2"/>
            <path d="M512.33 307.807L519.501 312.661L531.749 298.073" stroke="#666E78" stroke-width="5.376"/>
            <path d="M512.33 380.165L519.501 385.019L531.749 370.432" stroke="#666E78" stroke-width="5.376"/>
            <path d="M512.33 453.328L519.501 458.182L531.749 443.595" stroke="#666E78" stroke-width="5.376"/>
            <path d="M512.33 525.687L519.501 530.541L531.749 515.953" stroke="#666E78" stroke-width="5.376"/>
            <path d="M538.016 362.392H505.105V395.355H538.016V362.392Z" stroke="#B2B2B2" stroke-width="2"/>
            <path d="M538.016 434.751H505.105V467.714H538.016V434.751Z" stroke="#B2B2B2" stroke-width="2"/>
            <path d="M538.016 507.11H505.105V540.073H538.016V507.11Z" stroke="#B2B2B2" stroke-width="2"/>
            <path
                d="M333.209 539.931H470.313M333.209 218.336H470.313H333.209ZM333.209 234.416H470.313H333.209ZM333.209 250.495H470.313H333.209ZM333.209 290.695H470.313H333.209ZM333.209 306.775H470.313H333.209ZM333.209 322.854H470.313H333.209ZM333.209 363.054H470.313H333.209ZM333.209 379.133H470.313H333.209ZM333.209 395.213H470.313H333.209ZM333.209 435.412H470.313H333.209ZM333.209 451.492H470.313H333.209ZM333.209 467.572H470.313H333.209ZM333.209 507.771H470.313H333.209ZM333.209 523.851H470.313H333.209Z"
                stroke="#B2B2B2" stroke-width="2"/>
            <g filter="url(#filter0_d)">
              <path
                  d="M521.41 266.415C538.838 266.415 552.966 252.265 552.966 234.809C552.966 217.353 538.838 203.203 521.41 203.203C503.982 203.203 489.854 217.353 489.854 234.809C489.854 252.265 503.982 266.415 521.41 266.415Z"
                  fill="black"/>
            </g>
            <path
                d="M521.41 266.415C538.838 266.415 552.966 252.265 552.966 234.809C552.966 217.353 538.838 203.203 521.41 203.203C503.982 203.203 489.854 217.353 489.854 234.809C489.854 252.265 503.982 266.415 521.41 266.415Z"
                fill="white"/>
            <path
                d="M521.68 254.529C532.405 254.529 541.099 245.821 541.099 235.079C541.099 224.337 532.405 215.629 521.68 215.629C510.955 215.629 502.261 224.337 502.261 235.079C502.261 245.821 510.955 254.529 521.68 254.529Z"
                fill="#00C49A"/>
            <path d="M511.97 237.249L519.141 242.103L531.389 227.516" stroke="white" stroke-width="5.376"/>
            <path clip-rule="evenodd"
                  d="M153.945 780.26H206.855V808.145C206.855 822.756 195.011 834.6 180.4 834.6C165.789 834.6 153.945 822.756 153.945 808.145V780.26Z"
                  fill="white"
                  fill-rule="evenodd" stroke="#00C49A" stroke-width="1.43"/>
            <path
                d="M180.4 781.69C195.011 781.69 206.855 780.73 206.855 779.545C206.855 778.36 195.011 777.4 180.4 777.4C165.789 777.4 153.945 778.36 153.945 779.545C153.945 780.73 165.789 781.69 180.4 781.69Z"
                fill="#00C49A"/>
            <path clip-rule="evenodd"
                  d="M144.237 573.162C140.389 582.756 143.884 598.753 154.724 621.154C170.984 654.755 157.035 663.452 180.63 693.556C182.371 666.963 184.755 644.746 187.784 626.905C190.812 609.064 176.296 591.15 144.237 573.162ZM98.2528 651.697C103.315 668.293 114.962 679.419 133.193 685.073C160.54 693.556 173.479 714.044 173.479 721.41C177.794 698.303 169.219 682.379 147.757 673.637C126.294 664.896 109.793 657.583 98.2528 651.697ZM252.161 615.303C246.956 635.26 239.205 649.077 228.909 656.752C213.465 668.264 200.634 676.932 203.808 698.939C210.066 688.45 219.192 681.092 231.186 676.866C249.176 670.526 262.737 654.802 262.737 641.832C262.737 633.186 259.212 624.343 252.161 615.303Z"
                  fill="#00C49A"
                  fill-rule="evenodd"/>
            <path
                d="M144.237 573.778C169.776 597.029 181.83 616.873 180.4 633.31C178.255 657.965 174.61 680.283 183.582 700.831C192.555 721.378 185.066 742.653 180.4 754.822C177.289 762.935 176.574 771.414 178.255 780.26"
                stroke="#00C49A" stroke-width="1.716"/>
            <path
                d="M98.2528 651.697C104.457 661.803 118.075 671.575 139.108 681.015C170.658 695.175 175.897 706.185 173.278 728.369C170.658 750.554 168.643 760.064 175.994 779.545M184.057 779.545C181.676 769.906 184.508 761.169 192.555 753.333C204.625 741.578 198.332 715.621 203.433 700.406C208.533 685.19 209.866 679.565 233.882 665.631C249.892 656.342 256.276 640.576 253.035 618.333"
                stroke="#00C49A" stroke-width="1.716"/>
            <path clip-rule="evenodd"
                  d="M402.041 360.589C403.393 354.765 403.844 351.05 403.393 349.446C402.717 347.04 399.782 342.888 402.041 332.41C402.041 330.396 401.737 328.594 401.128 327.003C403.231 326.616 404.755 326.963 405.701 328.045C407.986 318.229 409.02 311.163 408.804 306.846C408.481 300.371 410.977 301.117 412.132 302.045C413.287 302.973 414.241 310.299 413.447 317.524C415.347 316.189 417.29 316.424 419.277 318.227C421.269 317.879 422.842 318.765 423.996 320.882C425.464 320.817 426.488 321.737 427.066 323.641C427.934 326.498 427.066 333.592 425.63 337.439C424.193 341.286 420.418 348.39 419.277 351.065C418.517 352.849 418.023 357.618 417.794 365.374L402.041 360.589Z"
                  fill="#FFAE9C"
                  fill-rule="evenodd"/>
            <path clip-rule="evenodd"
                  d="M330.926 636.824V603.029L292.76 601.259L296.574 641.491C292.264 650.566 290.109 660.05 290.109 669.943C290.109 674.451 291.991 689.665 292.76 704.574C294.522 738.746 295.903 785.417 294.365 798.464C290.301 805.169 290.448 810.508 294.806 814.481C297.343 816.794 307.969 822.37 326.684 831.209L335.095 824.477C326.185 819.126 320.68 814.135 318.579 809.503C312.72 796.583 314.355 778.21 315.021 771.606C319.941 722.79 325.242 677.863 330.926 636.824Z"
                  fill="#FFAE9C"
                  fill-rule="evenodd"/>
            <path clip-rule="evenodd"
                  d="M292.582 801.231C290.329 808.606 289.579 814.986 290.329 820.371C291.08 825.755 291.409 830.716 291.315 835.252H297.62V820.371C303.46 829.446 307.646 834.407 310.177 835.252C334.565 835.252 346.758 835.252 346.758 835.252C346.758 835.252 348.877 829.907 339.118 825.487C332.612 822.541 328.407 820.26 326.502 818.646C328.593 820.949 327.492 822.1 323.196 822.1C316.753 822.1 308.798 818.549 305.586 811.892C303.444 807.454 299.11 803.901 292.582 801.231Z"
                  fill="black"
                  fill-rule="evenodd"/>
            <path clip-rule="evenodd"
                  d="M286.926 636.824V603.029H248.76L252.574 641.49C248.264 650.566 246.109 660.05 246.109 669.943C246.109 674.451 247.991 689.665 248.76 704.574C250.522 738.746 251.903 785.417 250.365 798.464C246.301 805.169 246.448 810.507 250.806 814.481C253.343 816.794 263.969 822.37 282.684 831.209L291.095 824.477C282.185 819.126 276.68 814.135 274.579 809.502C268.72 796.582 270.355 778.21 271.021 771.606C275.941 722.79 281.242 677.863 286.926 636.824Z"
                  fill="#FFAE9C"
                  fill-rule="evenodd"/>
            <path clip-rule="evenodd"
                  d="M248.582 801.231C246.329 808.606 245.579 814.986 246.329 820.37C247.08 825.755 247.409 830.716 247.315 835.252H253.62V820.37C259.46 829.446 263.646 834.406 266.177 835.252C290.565 835.252 302.758 835.252 302.758 835.252C302.758 835.252 306.248 830.603 295.118 825.487C287.698 822.076 283.39 819.661 282.193 818.241C284.562 821.945 283.599 823.797 279.304 823.797C272.86 823.797 265.94 820.899 261.586 811.892C258.683 805.888 254.349 802.334 248.582 801.231Z"
                  fill="black"
                  fill-rule="evenodd"/>
            <path clip-rule="evenodd"
                  d="M250.526 502.126C241.508 513.422 238.746 528.14 242.238 546.277C245.731 564.415 246.945 593.028 245.88 632.115H334.849C341.278 607.892 343.815 585.809 342.459 565.866C339.934 528.734 331.002 503.419 331.002 490.731H257.969L250.526 502.126Z"
                  fill="#198997"
                  fill-rule="evenodd"/>
            <path clip-rule="evenodd"
                  d="M306.791 313.515C305.933 304.532 301.245 297.319 292.729 291.875C279.954 283.71 246.502 298.59 249.429 324.201C251.38 341.275 264.105 351.558 287.604 355.049L306.791 313.515Z"
                  fill="#7D1C1C"
                  fill-rule="evenodd"/>
            <path
                d="M232.478 329.395C233.893 333.017 235.2 335.421 236.399 336.607C241.505 341.659 248.129 341.601 253.372 336.346C257.526 345.78 265.355 351.457 276.857 353.375"
                stroke="#7D1C1C" stroke-width="1.21"/>
            <path clip-rule="evenodd"
                  d="M252.955 309.146C248.182 305.503 242.799 305.551 236.807 309.292C227.819 314.902 230.022 334.832 239.287 338.294C245.463 340.603 250.412 339.69 254.133 335.554L252.955 309.146Z"
                  fill="#7D1C1C"
                  fill-rule="evenodd"/>
            <mask id="mask0" height="79" mask-type="alpha" maskUnits="userSpaceOnUse" width="81" x="253" y="304">
              <path
                  d="M276.626 337.855C275.403 337.21 271.177 332.141 273.865 329.266C275.658 327.349 277.686 327.655 279.951 330.183C294.593 323.628 301.814 315.185 301.614 304.857C304.755 306.422 307.289 312.511 309.216 323.123C313.994 327.871 316.371 330.511 316.347 331.043C316.323 331.575 315.039 332.73 312.496 334.51C312.633 338.459 312.789 341.155 312.965 342.598C313.653 348.241 311.748 350.672 309.499 351.981C306.484 353.736 302.56 354.333 297.728 353.772L300.156 369.707L273.865 369.707L276.626 337.855Z"
                  fill="white"/>
            </mask>
            <g mask="url(#mask0)">
              <path
                  d="M276.626 337.855C275.403 337.21 271.177 332.141 273.865 329.266C275.658 327.349 277.686 327.655 279.951 330.183C294.593 323.628 301.814 315.185 301.614 304.857C304.755 306.422 307.289 312.511 309.216 323.123C313.994 327.871 316.371 330.511 316.347 331.043C316.323 331.575 315.039 332.73 312.496 334.51C312.633 338.459 312.789 341.155 312.965 342.598C313.653 348.241 311.748 350.672 309.499 351.981C306.484 353.736 302.56 354.333 297.728 353.772L300.156 369.707L273.865 369.707L276.626 337.855Z"
                  fill="#FFAE9C"/>
              <path clip-rule="evenodd"
                    d="M297.728 353.772L290.376 351.057C291.208 358.275 294.542 364.436 300.378 369.541L297.728 353.772Z"
                    fill="#F99B86"
                    fill-rule="evenodd"/>
              <path
                  d="M304.632 323.795C304 324.364 303.024 324.31 302.453 323.675C301.881 323.041 301.93 322.064 302.562 321.495C303.194 320.926 304.17 320.98 304.741 321.615C305.313 322.249 305.264 323.226 304.632 323.795Z"
                  fill="black"/>
              <path clip-rule="evenodd"
                    d="M312.744 339.337L306.668 339.667C308.557 342.025 310.668 343.049 313.002 342.739L312.744 339.337Z"
                    fill="white"
                    fill-rule="evenodd"/>
              <path d="M304.806 317.059C302.249 317.567 300.22 318.796 298.718 320.746" stroke="black"
                    stroke-width="1.21"/>
            </g>
            <path d="M252.458 308.594C248.786 318.855 249.091 328.106 253.372 336.346" stroke="white"
                  stroke-width="1.21"/>
            <path d="M301.613 304.857C304.093 315.92 296.866 324.247 279.932 329.839" stroke="#7D1C1C"
                  stroke-width="1.21"/>
            <mask id="mask1" height="153" mask-type="alpha" maskUnits="userSpaceOnUse" width="201" x="221" y="359">
              <path
                  d="M397.25 359.163L421.201 363.325C417.812 412.261 411.751 436.729 403.018 436.729C394.285 436.729 372.501 432.191 337.665 423.114C343.035 431.447 341.435 441.275 332.864 452.6C328.108 457.943 329.655 477.515 337.506 511.318L265.975 511.466V494.941L256.181 511.466L244.07 511.318C226.796 487.494 219.234 471.751 221.383 464.091C224.607 452.6 252.059 387.32 258.15 379.44C262.21 374.188 267.082 370.944 272.765 369.708C288.831 368.832 298.329 368.832 301.26 369.708C304.19 370.585 307.561 372.048 311.372 374.098C322.496 373.544 341.276 380.408 367.713 394.687L387.715 404.163L397.25 359.163ZM250.504 467.58L254.787 481.523C257.625 472.097 259.189 465.127 259.478 460.614C259.767 456.101 259.292 451.607 258.055 447.132L250.504 467.58Z"
                  fill="white"/>
            </mask>
            <g mask="url(#mask1)">
              <path
                  d="M397.25 359.163L421.201 363.325C417.812 412.261 411.751 436.729 403.018 436.729C394.285 436.729 372.501 432.191 337.665 423.114C343.035 431.447 341.435 441.275 332.864 452.6C328.108 457.943 329.655 477.515 337.506 511.318L265.975 511.466V494.941L256.181 511.466L244.07 511.318C226.796 487.494 219.234 471.751 221.383 464.091C224.607 452.6 252.059 387.32 258.15 379.44C262.21 374.188 267.082 370.944 272.765 369.708C288.831 368.832 298.329 368.832 301.26 369.708C304.19 370.585 307.561 372.048 311.372 374.098C322.496 373.544 341.276 380.408 367.713 394.687L387.715 404.163L397.25 359.163ZM250.504 467.58L254.787 481.523C257.625 472.097 259.189 465.127 259.478 460.614C259.767 456.101 259.292 451.607 258.055 447.132L250.504 467.58Z"
                  fill="white"/>
              <path clip-rule="evenodd"
                    d="M272.765 369.708C256.037 373.822 249.198 389.306 252.248 416.163C256.823 456.447 264.093 451.817 255.035 480.752C248.997 500.041 244.596 511.601 241.832 515.431L216.601 469.333L220.735 460.122L248.587 388.209L265.567 365.993L272.765 369.708Z"
                    fill="#D6DFF3"
                    fill-rule="evenodd"/>
              <path clip-rule="evenodd"
                    d="M312.611 372.001L263.374 372.894L275.757 365.993L300.689 367.469L312.611 372.001Z"
                    fill="#D6DFF3"
                    fill-rule="evenodd"/>
              <path clip-rule="evenodd" d="M388.321 399.82L386.183 415.62L369.641 394.86L388.321 399.82Z"
                    fill="#D6DFF3" fill-rule="evenodd"/>
              <path clip-rule="evenodd"
                    d="M340.825 423.961L323.954 419.849C335.201 426.499 340.825 432.314 340.825 437.292C342.484 434.605 343.141 432.284 342.795 430.328C342.449 428.373 341.793 426.25 340.825 423.961Z"
                    fill="#D6DFF3"
                    fill-rule="evenodd"/>
            </g>

            <defs>
              <filter id="filter0_d" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse" height="79.212"
                      width="79.112" x="478.854"
                      y="198.203">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dx="-3" dy="3"/>
                <feGaussianBlur stdDeviation="4"/>
                <feColorMatrix type="matrix"
                               values="0 0 0 0 0.100372 0 0 0 0 0.387639 0 0 0 0 0.687217 0 0 0 0.360741 0"/>
                <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow"/>
                <feBlend in="SourceGraphic" in2="effect1_dropShadow" mode="normal" result="shape"/>
              </filter>
            </defs>
          </svg>


        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
          class="d-flex align-items-center auth-bg "
          lg="5"
      >

        <b-col v-if="invitationDetails &&  invitationDetails.created_by"
               class="px-xl-2 mx-auto "
               cols="12"
               lg="8"
               md="9"

        >

          <h2 class="">
            <strong class="font-weight-500  " style="font-weight:900"> Invitation</strong>
          </h2>
          <!--          <p class="pb-2  black">You get invitation from <span class="font-weight-700 ">{{form.name}}</span> medical practice.</p>-->
          <p class="pb-2  black">You have been invited by <span
              class="font-weight-700 ">{{
              invitationDetails.created_by.first_name
            }} {{ invitationDetails.created_by.last_name }}</span>
            to join <span class="font-weight-700 ">{{ invitationDetails.practice.name }}</span>.</p>


          <b-row class="align-items-center">
            <b-col cols="3">
              <b-button size="sm" variant="primary" @click="acceptInvitations()">Accept</b-button>
            </b-col>
            <b-col cols="3">
              <b-button size="sm" variant="danger" @click="rejectInvitations()">Decline</b-button>
            </b-col>
          </b-row>
          <div class="mt-1">
            <!--            <p>You want to log-->
            <!--              <b-button variant="link" @click="$router.push('/')">Sign In</b-button>-->
            <!--            </p>-->
          </div>
        </b-col>

      </b-col>
      <!-- /Login-->
    </b-row>

    <b-row v-if="hideFirstPage && currentUser" class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!--        <vuexy-logo />-->
        <h1>
          <strong class="font-weight-700 font-large-3" style="font-weight:900">hyre<span
              class="text-success">.</span></strong>
        </h1>

        <h2 class="brand-text text-primary ml-1"/>
      </b-link>
      <b-col
          class=" d-flex align-items-center reg_success" cols="12"
          style="background-color: white;"
      >
        <div class="w-100 d-lg-flex text-center align-items-center justify-content-center px-5">

          <b-row class="d-flex align-items-center justify-content-center ">
            <b-col cols="12">
              <div class="text-center">
                <svg v-if="confirmstatus ==='accept'" fill="none" height="85" viewBox="0 0 68 85" width="68"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M48.988 56.6295L54.8738 57.3964L55.1342 51.4632C55.1738 50.7708 55.6675 50.1887 56.3447 50.0381L62.1375 48.7499L60.3595 43.0783C60.1616 42.4171 60.4243 41.7042 61.0038 41.3304L66 38.1412L62.3979 33.4352C61.9696 32.8861 61.9696 32.1139 62.3979 31.5648L66 26.8588L61.0038 23.6696C60.4243 23.2958 60.1616 22.5829 60.3595 21.9217L62.1375 16.2502L56.3447 14.9775C55.6657 14.8251 55.1738 14.2328 55.1492 13.5368L54.8738 7.61925L48.988 8.37049C48.3012 8.4641 47.639 8.07827 47.3792 7.43504L45.1112 1.96211L39.854 4.69107C39.238 5.00909 38.4863 4.87769 38.0154 4.36946L34 0L29.9846 4.36946C29.5155 4.88069 28.7608 5.01329 28.1448 4.69107L22.8888 1.96211L20.6208 7.43504C20.3562 8.07467 19.697 8.45689 19.0108 8.37049L13.1262 7.60364L12.8658 13.5368C12.8262 14.2292 12.3313 14.8113 11.6547 14.9619L5.86188 16.2502L7.63926 21.9217C7.83781 22.5829 7.57568 23.2958 6.99561 23.6696L2 26.8588L5.60094 31.5648C6.03044 32.1139 6.03044 32.8861 5.60094 33.4352L2 38.1412L6.99561 41.3304C7.57568 41.7042 7.83781 42.4171 7.63926 43.0783L5.86188 48.7499L11.6547 50.0225C12.3343 50.1749 12.8256 50.7672 12.8502 51.4632L13.1262 57.3814L19.0108 56.6295C19.6994 56.5293 20.3664 56.9175 20.6208 57.565L22.7809 62.7775L22.9806 62.4252L23.5019 62.7163L28.1448 60.3089C28.3625 60.1961 28.6055 60.1373 28.8508 60.1403C29.2809 60.1361 29.6936 60.3143 29.9846 60.6305L34 65L38.0154 60.6305C38.4863 60.1223 39.238 59.9909 39.854 60.3089L44.4981 62.7163L45.0188 62.4252L45.2179 62.7775L47.3792 57.565C47.6432 56.9265 48.3024 56.5431 48.988 56.6295ZM34 57.0285C20.4576 57.0285 9.47902 46.0467 9.47902 32.5003C9.47902 18.9533 20.4576 7.97207 34 7.97207C47.5424 7.97207 58.521 18.9533 58.521 32.5003C58.5048 46.0401 47.5358 57.0123 34 57.0285Z"
                      fill="#13BB84"/>
                  <path
                      d="M53 32.4997C53 43.2691 44.2696 52 33.5003 52C22.7309 52 14 43.2691 14 32.4997C14 21.7298 22.7309 13 33.5003 13C44.2696 13 53 21.7298 53 32.4997Z"
                      fill="#13BB84"/>
                  <path
                      d="M33.4852 46.7408C41.8358 46.7408 48.6052 39.9713 48.6052 31.6208C48.6052 23.2702 41.8358 16.5008 33.4852 16.5008C25.1347 16.5008 18.3652 23.2702 18.3652 31.6208C18.3652 39.9713 25.1347 46.7408 33.4852 46.7408Z"
                      fill="#13BB84"/>
                  <path d="M25.9253 33.3076L31.5086 37.0809L41.0453 25.7409" stroke="white" stroke-width="3.024"/>
                  <path
                      d="M18.3658 60L12.0669 60.8393C11.6479 60.8855 11.2271 60.7565 10.9012 60.4817C10.7572 60.357 10.6387 60.2037 10.5527 60.031L0 78.7847L6.46546 77.7751C7.16653 77.6681 7.84566 78.0756 8.0999 78.7536L10.4775 85L21 66.3236C20.9247 66.233 20.859 66.1338 20.8032 66.0286L18.3658 60ZM57.4467 60.031C57.3566 60.2001 57.2392 60.3527 57.0988 60.4817C56.7669 60.7565 56.342 60.8855 55.9177 60.8393L49.6348 60L47.1967 66.0286C47.1416 66.1338 47.0759 66.233 47 66.3236L57.5231 85L59.8995 78.7536C60.1514 78.0713 60.8329 77.6632 61.5351 77.7751L68 78.7847L57.4467 60.031Z"
                      fill="#13BB84"/>
                </svg>
                <svg v-if="confirmstatus ==='rejected'" fill="none" height="85" viewBox="0 0 68 85" width="68"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M48.988 56.6295L54.8738 57.3964L55.1342 51.4632C55.1738 50.7708 55.6675 50.1887 56.3447 50.0381L62.1375 48.7499L60.3595 43.0783C60.1616 42.4171 60.4243 41.7042 61.0038 41.3304L66 38.1412L62.3979 33.4352C61.9696 32.8861 61.9696 32.1139 62.3979 31.5648L66 26.8588L61.0038 23.6696C60.4243 23.2958 60.1616 22.5829 60.3595 21.9217L62.1375 16.2502L56.3447 14.9775C55.6657 14.8251 55.1738 14.2328 55.1492 13.5368L54.8738 7.61925L48.988 8.37049C48.3012 8.4641 47.639 8.07827 47.3792 7.43504L45.1112 1.96211L39.854 4.69107C39.238 5.00909 38.4863 4.87769 38.0154 4.36946L34 0L29.9846 4.36946C29.5155 4.88069 28.7608 5.01329 28.1448 4.69107L22.8888 1.96211L20.6208 7.43504C20.3562 8.07467 19.697 8.45689 19.0108 8.37049L13.1262 7.60364L12.8658 13.5368C12.8262 14.2292 12.3313 14.8113 11.6547 14.9619L5.86188 16.2502L7.63926 21.9217C7.83781 22.5829 7.57568 23.2958 6.99561 23.6696L2 26.8588L5.60094 31.5648C6.03044 32.1139 6.03044 32.8861 5.60094 33.4352L2 38.1412L6.99561 41.3304C7.57568 41.7042 7.83781 42.4171 7.63926 43.0783L5.86188 48.7499L11.6547 50.0225C12.3343 50.1749 12.8256 50.7672 12.8502 51.4632L13.1262 57.3814L19.0108 56.6295C19.6994 56.5293 20.3664 56.9175 20.6208 57.565L22.7809 62.7775L22.9806 62.4252L23.5019 62.7163L28.1448 60.3089C28.3625 60.1961 28.6055 60.1373 28.8508 60.1403C29.2809 60.1361 29.6936 60.3143 29.9846 60.6305L34 65L38.0154 60.6305C38.4863 60.1223 39.238 59.9909 39.854 60.3089L44.4981 62.7163L45.0188 62.4252L45.2179 62.7775L47.3792 57.565C47.6432 56.9265 48.3024 56.5431 48.988 56.6295ZM34 57.0285C20.4576 57.0285 9.47902 46.0467 9.47902 32.5003C9.47902 18.9533 20.4576 7.97207 34 7.97207C47.5424 7.97207 58.521 18.9533 58.521 32.5003C58.5048 46.0401 47.5358 57.0123 34 57.0285Z"
                      fill="#FF4848"/>
                  <path
                      d="M53 32.4997C53 43.2691 44.2696 52 33.5003 52C22.7309 52 14 43.2691 14 32.4997C14 21.7298 22.7309 13 33.5003 13C44.2696 13 53 21.7298 53 32.4997Z"
                      fill="#FF4848"/>
                  <path
                      d="M33.4852 46.7408C41.8358 46.7408 48.6052 39.9713 48.6052 31.6208C48.6052 23.2702 41.8358 16.5008 33.4852 16.5008C25.1347 16.5008 18.3652 23.2702 18.3652 31.6208C18.3652 39.9713 25.1347 46.7408 33.4852 46.7408Z"
                      fill="#FF4848"/>
                  <path d="M25.9253 33.3076L31.5086 37.0809L41.0453 25.7409" stroke="white" stroke-width="3.024"/>
                  <path
                      d="M18.3658 60L12.0669 60.8393C11.6479 60.8855 11.2271 60.7565 10.9012 60.4817C10.7572 60.357 10.6387 60.2037 10.5527 60.031L0 78.7847L6.46546 77.7751C7.16653 77.6681 7.84566 78.0756 8.0999 78.7536L10.4775 85L21 66.3236C20.9247 66.233 20.859 66.1338 20.8032 66.0286L18.3658 60ZM57.4467 60.031C57.3566 60.2001 57.2392 60.3527 57.0988 60.4817C56.7669 60.7565 56.342 60.8855 55.9177 60.8393L49.6348 60L47.1967 66.0286C47.1416 66.1338 47.0759 66.233 47 66.3236L57.5231 85L59.8995 78.7536C60.1514 78.0713 60.8329 77.6632 61.5351 77.7751L68 78.7847L57.4467 60.031Z"
                      fill="#FF4848"/>
                </svg>


              </div>
              <h2 v-if="confirmstatus ==='accept'" class="font-weight-700 mt-2">Invitation Accepted</h2>
              <h2 v-if="confirmstatus ==='rejected'" class="font-weight-700 mt-2">Invitation Decline</h2>
              <!--              <p class="font-weight-bold">Your password has been successfully updated.</p>-->
              <b-button block class="bg-black mt-2" @click="$router.push('/')">Dashbord</b-button>
            </b-col>
          </b-row>

        </div>
      </b-col>
    </b-row>


  </div>
</template>

<script>
import {password, required} from '@validations'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay,
  BRow
} from 'bootstrap-vue'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'

import invitations from '@/apis/modules/invitations'
import {mapActions, mapGetters} from 'vuex'


export default {
  name: 'invitations-accept',
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,

    // BImg,
    BForm,
    BButton,
    BOverlay,
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  data () {
    return {
      hideFirstPage: false,
      new_password: '',
      is_pw_resetted: false,
      confirm_password: '',
      loading: false,
      required,
      password,
      confirmstatus: '',
      invitationDetails: []
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
    // passwordToggleIcon() {
    //   return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    // },
    // imgUrl() {
    //   if (store.state.appConfig.layout.skin === 'dark') {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
    //     return this.sideImg
    //   }
    //   return this.sideImg
    // },

  },
  mounted () {
    this.checkUserLogin()

  },


  methods: {
    ...mapActions(['logout']),

    async checkUserLogin () {

      if (this.currentUser && this.currentUser.user.email === this.$route.query.email) {
        await this.showInvitations()
        return
      } else if (this.currentUser && localStorage.getItem('registered')) {
        localStorage.removeItem('registered')
        if (this.currentUser.user.email === this.$route.query.email) await this.showInvitations()
        else window.location = '/'

        return
      } else if (this.currentUser) {
        localStorage.setItem('redirectUrl', window.location.href)
        await this.logout()
        return
      }

      localStorage.setItem('redirectUrl', window.location.href)
      window.location = '/'

/*
      return


      if (localStorage.getItem('isLoggedIn') !== null) {
        alert(1)

        if (localStorage.getItem('toInvitation') !== null) {
          alert(2)
          if (this.$route.params.email !== this.currentUser.user.email) {
            this.showErrorMessage('You dont have access to this Invitation')
            await localStorage.setItem('redirectUrl', window.location.href)
            await localStorage.setItem('toInvitation', 'true')
            alert(3)
            await this.logout()
          } else {
            alert(4)
            await this.showInvitations()
            await localStorage.removeItem('toInvitation')
          }

        } else {
          alert(5)
          await localStorage.setItem('toInvitation', 'true')
          await localStorage.setItem('redirectUrl', window.location.href)
          await this.logout()
        }
      } else {
        alert(6)
        await localStorage.setItem('redirectUrl', window.location.href)
        window.location = '/'
      }*/
    },
    async showInvitations () {
      try {
        this.showSendInvitation = true
        const showInvitationsResponse = await invitations.showInvitations(this.$route.params.token)
        this.invitationDetails = showInvitationsResponse.data
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    async acceptInvitations () {
      try {
        const showInvitationsResponse = await invitations.accept(this.invitationDetails.token)
        this.showSendInvitation = false
        this.showSuccessMessage('Invitation Accept Successful')
        await localStorage.removeItem('toInvitation')
        this.hideFirstPage = 'true'
        this.confirmstatus = 'accept'
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    async rejectInvitations () {
      try {
        const showInvitationsResponse = await invitations.reject(this.invitationDetails.token)
        this.showSendInvitation = false
        this.showSuccessMessage('Invitation Reject Successful')
        await localStorage.removeItem('toInvitation')
        this.hideFirstPage = 'true'
        this.confirmstatus = 'rejected'

      } catch (error) {
        this.convertAndNotifyError(error)
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/vue/pages/page-auth.scss";
</style>
